import React from 'react';
import './Vault.scss';
import {
    Body1,
    Headline4
} from '@material/react-typography';
import TextField, { Input } from '@material/react-text-field';
import Button from '@material/react-button';
import Disqus from 'disqus-react';

const disqusConfig = {
    url: 'http://emmeerkat.com/vault',
    identifier: '',
    title: '',
};

class Vault extends React.Component {
    constructor(props) {
        super(props);
        this.state = { password: '' };

        this.handleLogInClick = this.handleLogInClick.bind(this);
        this.handleLogOutClick = this.handleLogOutClick.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    handleLogInClick() {
        this.props.onLogIn(this.state.password);
        this.setState({ password: '' });
    }

    handleLogOutClick() {
        this.props.onLogOut();
    }

    handleKeyDown(e) {
        if (e.key === 'Enter') {
            this.handleLogInClick();
        }
    }

    render() {
        let vaultContent;
        if (this.props.vaultContent === '') {
            vaultContent = <article>
                <Body1 id='error'>You need to log in as the Emmeerkat to access this page!</Body1>
                <div className='flex-container-vertical'>
                    <TextField outlined label='Password'>
                        <Input type='password' value={this.state.password} onChange={(e) => this.setState({ password: e.currentTarget.value })} onKeyDown={this.handleKeyDown} />
                    </TextField>
                    <Button raised onClick={this.handleLogInClick}>Log in</Button>
                </div>
            </article>
        } else {
            vaultContent = <article>
                <div dangerouslySetInnerHTML={{ __html: this.props.vaultContent }} />
                <div className='flex-container-vertical'>
                    <Button id='logoutbutton' raised onClick={this.handleLogOutClick}>Log out</Button>
                </div>
                <Disqus.DiscussionEmbed shortname={this.props.disqus} config={disqusConfig} />
            </article >
        }

        return (
            <React.Fragment>
                <Headline4 id='cardtitle'>The Emmeerkatvault</Headline4>
                {vaultContent}
            </React.Fragment>
        );
    }
}

export default Vault;
