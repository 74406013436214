import React from 'react';
import './App.scss';
import Home from './Home';
import Vault from './Vault';
import Card from '@material/react-card';
import Button from '@material/react-button';
import Dialog, {
  DialogTitle,
  DialogContent,
  DialogFooter,
  DialogButton,
} from '@material/react-dialog';
import firebase from 'firebase';
import firebaseConfig from './firebaseConfig';
import olaf from './olaf-snowman.gif'

firebase.initializeApp(firebaseConfig);
const vaultRef = firebase.firestore().collection('emmeerkat').doc('vault');

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dialogIsOpen: false, home: true, vaultContent: '', disqus: '' };

    this.handleOlafClick = this.handleOlafClick.bind(this);
    this.handleHomeClick = this.handleHomeClick.bind(this);
    this.handleVaultClick = this.handleVaultClick.bind(this);

    this.handleLogIn = this.handleLogIn.bind(this);
    this.handleLogOut = this.handleLogOut.bind(this);
  }

  handleOlafClick() {
    this.setState({ dialogIsOpen: true });
  }

  handleHomeClick() {
    this.setState({ home: true });
  }

  handleVaultClick() {
    this.setState({ home: false });
  }

  handleLogIn(password) {
    firebase.auth().signInWithEmailAndPassword('emmeerkat@emmeerkat.com', password + password).then(() => {
      vaultRef.get().then((doc) => {
        if (doc.exists) {
          this.setState({ vaultContent: doc.data().content, disqus: doc.data().disqus });
        }
      }).catch(() => { });
    }).catch(() => { });
  }

  handleLogOut() {
    firebase.auth().signOut().then(() => {
      this.setState({ vaultContent: '', disqus: '' });
    }).catch(() => { });
  }

  fetchVaultContent() {

  }

  render() {
    let cardContent;
    if (this.state.home) {
      cardContent = <Home />
    } else {
      cardContent = <Vault vaultContent={this.state.vaultContent} disqus={this.state.disqus} onLogIn={this.handleLogIn} onLogOut={this.handleLogOut} />
    }

    return (
      <React.Fragment>
        <div id='figures'>
          <div id='monkey' />
          <div id='bob' onClick={this.handleOlafClick} />
          <div id='pinchy' />
          <div id='vassily' />
          <div id='quokka' />
        </div>
        <Card>
          <Button raised id='homebutton' onClick={this.handleHomeClick}>The Emmeerkat</Button>
          <Button raised id='vaultbutton' onClick={this.handleVaultClick}>The Emmeerkatvault</Button>
          {cardContent}
        </Card>
        <Dialog open={this.state.dialogIsOpen} onClose={(action) => this.setState({ action, dialogIsOpen: false })}>
          <DialogTitle>Do you wanna build a snowmaaaaaaaan? :D</DialogTitle>
          <DialogContent>
            <img src={olaf} alt='' />
          </DialogContent>
          <DialogFooter>
            <DialogButton action='accept' isDefault>I doooooooo! :D</DialogButton>
          </DialogFooter>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default App;
