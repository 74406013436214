const firebaseConfig = {
    apiKey: "AIzaSyBWnMAKbZ2iQmqGkK5i5KnBCOsbIe8aQLo",
    authDomain: "project-1335105425730788546.firebaseapp.com",
    databaseURL: "https://project-1335105425730788546.firebaseio.com",
    projectId: "project-1335105425730788546",
    storageBucket: "project-1335105425730788546.appspot.com",
    messagingSenderId: "813363238528",
    appId: "1:813363238528:web:2510291c94f767d9713865"
};

export default firebaseConfig;