import React from 'react';
import {
    Body1,
    Headline4
} from '@material/react-typography';

class Home extends React.Component {
    render() {
        return (
            <React.Fragment>
                <Headline4 id='cardtitle'>The Emmeerkat</Headline4>
                <article>
                    <Body1>The Emmeerkat is a curious breed, its name given to it because of its fondness for Meerkats. It can be compared to the Meerkat in several ways, while perhaps not as furry, it is at least as cute and cuddly as the Meerkat is.</Body1>
                    <Body1>The Emmeerkat loves to tickle. If it is not busy tickling there is a good chance it has got lost somewhere, in which case the entrance or exit has obviously been moved!</Body1>
                    <Body1>Although typically a very busy creature, the Emmeerkat is also known to enjoy long hot baths, and late mornings in a warm soft bed. In terms of cuisine the Emmeerkat prefers wine and nachos or pizza, perhaps with a nibble of chocolate after.</Body1>
                    <Body1>The Emmeerkat is an exceedingly good salsa dancer, something she loves showing off in expensive dresses at parties! But the Emmeerkat has many more good qualities as well, she is as clever as they get, and kind to boot! She deserves all the happiness in the world!</Body1>
                </article>
            </React.Fragment>
        );
    }
}

export default Home;
